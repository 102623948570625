import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";


export default function createSvgIcon(path: React.ReactNode, displayName: string) {

  const Component = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
      <SvgIcon data-mui-test={`${displayName}Icon`} ref={ref} {...props}>
        {path}
      </SvgIcon>
    ))
  );

  if (process.env.NODE_ENV !== "production") {
    Component.displayName = `${displayName}Icon`;
  }

  // Component.muiName = SvgIcon.muiName;

  return Component;
}
