import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <g>
    <path fill="#e91e63" d="M 9.1846,12.5968 C 7.9086,12.5968 6.8737,13.6317 6.8737,14.9077 L 6.8737,20.6882 C 6.8737,21.9642 7.9086,22.9991 9.1846,22.9991 10.4606,22.9991 11.4955,21.9642 11.4955,20.6882 L 11.4955,14.9077 C 11.4946,13.6317 10.4597,12.5968 9.1846,12.5968 Z" />
    <path fill="#e91e63" d="M 1.0959,14.9077 C 1.0959,16.1846 2.1318,17.2204 3.4087,17.2204 4.6856,17.2204 5.7214,16.1846 5.7214,14.9077 L 5.7214,12.5949 3.4105,12.5949 C 3.4096,12.5949 3.4096,12.5949 3.4087,12.5949 2.1318,12.5949 1.0959,13.6307 1.0959,14.9077 Z" />
    <path fill="#00bcd4" d="M 9.1873,0.9991 C 9.1864,0.9991 9.1855,0.9991 9.1846,0.9991 7.9077,0.9991 6.8718,2.0349 6.8718,3.3118 6.8718,4.5888 7.9077,5.6246 9.1846,5.6246 L 11.4955,5.6246 11.4955,3.3118 C 11.4955,3.3109 11.4955,3.3091 11.4955,3.3072 11.4946,2.0322 10.4615,0.9991 9.1873,0.9991 Z" />
    <path fill="#00bcd4" d="M 3.3931,11.4225 L 9.1846,11.4225 C 10.4615,11.4225 11.4973,10.3867 11.4973,9.1098 11.4973,7.8328 10.4615,6.797 9.1846,6.797 L 3.3931,6.797 C 2.1162,6.797 1.0803,7.8328 1.0803,9.1098 1.0803,10.3867 2.1162,11.4225 3.3931,11.4225 Z" />
    <path fill="#4caf50" d="M 20.7474,6.7961 C 19.4723,6.7961 18.4393,7.8292 18.4393,9.1043 L 18.4393,9.1088 18.4393,11.4216 20.7502,11.4216 C 22.0271,11.4216 23.0629,10.3857 23.0629,9.1088 23.0629,7.8319 22.0271,6.7961 20.7502,6.7961 20.7493,6.7961 20.7483,6.7961 20.7474,6.7961 Z" />
    <path fill="#4caf50" d="M 12.6661,3.3118 L 12.6661,9.1098 C 12.6661,10.3857 13.701,11.4207 14.977,11.4207 16.253,11.4207 17.2879,10.3857 17.2879,9.1097 L 17.2879,3.3118 C 17.2879,2.0358 16.253,1.0009 14.977,1.0009 13.701,1.0009 12.6661,2.0358 12.6661,3.3118 Z" />
    <path fill="#ff9800" d="M 17.287,20.6863 C 17.287,19.4103 16.2521,18.3754 14.9761,18.3754 L 12.6652,18.3754 12.6652,20.6882 C 12.6661,21.9632 13.7001,22.9972 14.9761,22.9972 16.2521,22.9972 17.287,21.9623 17.287,20.6863 Z" />
    <path fill="#ff9800" d="M 20.7676,12.5949 L 14.9761,12.5949 C 13.6992,12.5949 12.6633,13.6307 12.6633,14.9077 12.6633,16.1846 13.6992,17.2204 14.9761,17.2204 L 20.7676,17.2204 C 22.0445,17.2204 23.0803,16.1846 23.0803,14.9077 23.0803,13.6307 22.0445,12.5949 20.7676,12.5949 Z" />
  </g>,
  "Slack"
);
