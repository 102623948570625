import React from "react";
import Loader from "./Loader";
import Oops from "./Oops";

interface IAsyncComponentState {
  component: React.ComponentType | null;
}

type ImportComponentType = () => Promise<{ default: React.ComponentType }>;


/**
 * asyncComponent
 */
export default function asyncComponent(importComponent: ImportComponentType) {

  class AsyncComponent extends React.Component<any, IAsyncComponentState> {
    private mounted;

    constructor(props: any) {
      super(props);
      this.mounted = true;

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      try {
        const { default: component } = await importComponent();
        if (this.mounted) {
          this.setState({
            component: component,
          });
        }
      } catch (err: any) {
        if (this.mounted) {
          this.setState({
            component: () => <Oops error={err?.message} />
          });
        }
      }
    }

    async componentWillUnmount() {
      this.mounted = false;
    }

    render() {
      const Component = this.state.component;
      return (
        Component
          ? <Component {...this.props} />
          : <Loader />
      )
    }
  }

  return AsyncComponent;
}
