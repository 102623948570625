import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <g>
    <path fillRule="evenodd" fill="#293e40" d="M 0.0,24.0 L 0.0,0.0 24.0,0.0 24.0,24.0 0.0,24.0 Z" />
    <path fillRule="evenodd" fill="#80b5a0" d="M 12.6753,12.4054 C 12.6753,11.3969 11.8645,10.579 10.8646,10.579 9.8648,10.579 9.054,11.3969 9.054,12.4054 9.054,13.4139 9.8648,14.2318 10.8646,14.2318 11.8645,14.2318 12.6753,13.4139 12.6753,12.4054 Z M 14.4352,12.3951 C 14.4352,10.4544 12.8171,8.8804 10.8219,8.8804 8.8267,8.8804 7.2087,10.4544 7.2087,12.3951 7.2087,13.4375 7.7089,14.6437 8.4167,15.0178 9.1246,15.392 9.6957,14.5118 10.8532,14.5128 12.0107,14.5138 12.6946,15.5285 13.3459,14.9099 14.0179,14.2716 14.4352,13.3807 14.4352,12.3951 Z" />
    <path fillRule="evenodd" fill="#ffffff" d="M 17.8145,8.9994 L 16.5777,12.5659 15.4614,9.0005 13.8804,9.0005 16.0755,14.9983 17.365,14.9954 18.5449,11.6163 19.7248,14.9951 21.0143,14.998 23.2094,9.0002 21.6284,9.0002 20.5121,12.5657 19.2753,8.9991 17.8145,8.9994 Z" />
    <path fillRule="evenodd" fill="#ffffff" d="M 6.7557,11.23 L 6.7762,14.9968 5.1952,14.9968 5.2336,11.7116 C 5.2138,11.0067 4.9021,10.6295 4.4824,10.454 4.1266,10.3052 3.247,10.3052 2.6159,11.0881 L 2.5874,14.9954 1.0064,14.9954 1.0064,8.9967 2.5874,8.9967 2.5946,9.4335 C 3.3161,8.9967 4.4231,8.7331 5.2929,9.1041 5.771,9.308 6.8051,10.0182 6.7557,11.23 Z" />
    <path fill="#ffffff" d="M 21.5088,14.1862 L 22.1408,14.1862 22.1408,14.3406 21.9139,14.3406 21.9139,14.9985 21.7366,14.9985 21.7366,14.3406 21.5088,14.3406 Z" />
    <path fill="#ffffff" d="M 22.2372,14.1862 L 22.4623,14.1862 22.6184,14.6209 22.7755,14.1862 23.0005,14.1862 23.0005,14.9985 22.8334,14.9985 22.8334,14.4041 22.6754,14.8419 22.5633,14.8419 22.4053,14.4041 22.4053,14.9985 22.2372,14.9985 Z" />
  </g>,
  "Servicenow"
);
