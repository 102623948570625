import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <g>
    <path fill="#2684ff" d="M 20.1882,1.9812 L 10.5502,1.9812 C 10.5502,4.3835 12.4949,6.3283 14.8973,6.3283 L 16.6704,6.3283 16.6704,8.0442 C 16.6704,10.4466 18.6152,12.3913 21.0175,12.3913 L 21.0175,2.8105 C 21.0175,2.3529 20.6457,1.9812 20.1882,1.9812 Z" />
    <path fill="#2684ff" d="M 15.4122,6.7858 L 5.7742,6.7858 C 5.7742,9.1882 7.719,11.1329 10.1213,11.1329 L 11.8944,11.1329 11.8944,12.8489 C 11.8944,15.2513 13.8392,17.196 16.2416,17.196 L 16.2416,7.6152 C 16.2416,7.1576 15.8697,6.7858 15.4122,6.7858 Z" />
    <path fill="#2684ff" d="M 10.6362,11.5905 L 0.9982,11.5905 C 0.9982,13.9929 2.943,15.9376 5.3453,15.9376 L 7.1185,15.9376 7.1185,17.6536 C 7.1185,20.0559 9.0633,22.0007 11.4656,22.0007 L 11.4656,12.4199 C 11.4656,11.9623 11.0938,11.5905 10.6362,11.5905 Z" />
  </g>,
  "Jira"
);
