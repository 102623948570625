import styled from "styled-components";

export const Center = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

export default Center;
