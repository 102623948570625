import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <g>
    <path fill="#2684ff" d="M 12.054,11.9595 C 15.0804,11.9595 17.5337,9.5061 17.5337,6.4797 17.5337,3.4534 15.0804,1.0 12.054,1.0 9.0276,1.0 6.5742,3.4534 6.5742,6.4797 6.5742,9.5061 9.0276,11.9595 12.054,11.9595 Z" />
    <path fill="#2684ff" d="M 11.5923,22.8574 C 8.0212,20.5793 5.035,17.47 2.9109,13.7759 2.7569,13.468 2.8493,13.0985 3.1571,12.9446 L 3.1879,12.9446 7.3439,10.9128 C 7.621,10.7589 7.9904,10.882 8.1443,11.1591 10.2377,14.6686 13.347,17.5008 17.072,19.2248 15.7174,20.6101 14.1782,21.8415 12.5466,22.8882 12.2387,23.0421 11.8693,23.0421 11.5923,22.8574 Z" />
    <path fill="#2684ff" d="M 12.5159,22.8574 C 16.087,20.5793 19.0731,17.47 21.1973,13.7759 21.3512,13.468 21.2588,13.0985 20.951,12.9446 L 20.9202,12.9446 16.7642,10.9128 C 16.4872,10.7589 16.1178,10.882 15.9638,11.1591 13.8705,14.6686 10.7611,17.5008 7.0362,19.2248 8.3907,20.6101 9.9299,21.8415 11.5616,22.8882 11.8694,23.0421 12.2388,23.0421 12.5159,22.8574 Z" />
  </g>,
  "Opsgenie"
);
