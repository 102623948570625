import { CircularProgress, Container } from "@material-ui/core";
import { FC } from "react";
import styled from "styled-components";


const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
` as typeof Container;


export const Loader: FC = () => {
  return (
    <Root maxWidth="sm">
      <CircularProgress disableShrink color="primary" variant="indeterminate" />
    </Root>
  );
}

export default Loader;
