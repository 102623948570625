import { Button, Grid, Link, Typography } from "@material-ui/core";
import { FC, Fragment } from "react";
import { PUBLIC_URL } from "../constants/PUBLIC_URL";
import { Info as InfoIcon } from "../icons";
import Center from "./Center";


export interface OOPSProps {
  error?: Error
}


export const Oops: FC<OOPSProps> = ({ error }) => {
  return (
    <Fragment>
      <Center>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={4}
        >
          <Grid item>
            <InfoIcon fontSize="large" color="error"></InfoIcon>
          </Grid>
          <Grid item>
            <Typography align="center" variant="h3">Oops!</Typography>
            <Typography align="center" variant="h6">Something went wrong.</Typography>
          </Grid>
          <Grid item>
            <Typography align="center" variant="body1">
              Please try again or go to <Link href={`${PUBLIC_URL}/`}>Home page.</Link>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => document.location.reload()}>
              Try again
            </Button>
          </Grid>
        </Grid>
      </Center>
      <Typography color="textSecondary" variant="caption">{error ? `Details: ${error}` : ""}</Typography>
    </Fragment>
  );
};

export default Oops